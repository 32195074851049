import React from 'react';
import { Helmet } from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: siteUrl
        defaultImage: image
      }
    }
    favicon: file(relativePath: { eq: "favicon.png" }) {
      childImageSharp {
        fixed(width: 150) {
          src
        }
      }
    }
  }
`;

const SEO = ({ title, description, image, location }) => {
  const { pathname } = location;
  const breadcrumbs = pathname.split('/').filter(Boolean);

  return (
    <StaticQuery
      query={query}
      render={({
        site: {
          siteMetadata: {
            defaultTitle,
            titleTemplate,
            defaultDescription,
            siteUrl,
          },
        },
        favicon: {
          childImageSharp: {
            fixed: { src },
          },
        },
      }) => {
        const seo = {
          title: title || defaultTitle,
          description: description || defaultDescription,
          image: `${siteUrl}${image || src}`,
          url: `${siteUrl}${pathname || '/'}`,
        };
        return (
          <>
            <Helmet title={seo.title} titleTemplate={titleTemplate}>
              <link rel='shortcuticon' href={image} />
              <meta name='description' content={seo.description} />
              <meta name='image' content={seo.image} />
              <meta property='og:type' content='website' />
              {seo.url && <meta property='og:url' content={seo.url} />}
              {seo.title && <meta property='og:title' content={seo.title} />}
              {seo.description && (
                <meta property='og:description' content={seo.description} />
              )}
              {seo.image && <meta property='og:image' content={seo.image} />}
              <script type='application/ld+json'>
                {`{
                "@context": "https://schema.org/",
                "@type": "BreadcrumbList",
                "itemListElement": [
                  {
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "${siteUrl}"
                  },
                  ${
                    breadcrumbs !== null && breadcrumbs.length >= 1
                      ? `{
                      "@type": "ListItem",
                      "position": 2,
                      "name": "${breadcrumbs[0]}",
                      "item": "${siteUrl}/${breadcrumbs[0]}"
                    }`
                      : ``
                  },
                  ${
                    breadcrumbs !== null && breadcrumbs.length >= 2
                      ? `{
                      "@type": "ListItem",
                      "position": 3,
                      "name": "${breadcrumbs[1]}",
                      "item": "${siteUrl}/${breadcrumbs[0]}/${breadcrumbs[1]}"
                    }`
                      : ``
                  }
                ]
              }`}
              </script>
            </Helmet>
          </>
        );
      }}
    />
  );
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  location: PropTypes.object,
};
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: null,
};

export default SEO;
