/* eslint-disable import/prefer-default-export */
import styled from 'styled-components';

export const StyledRibbon = styled.div`
  position: relative;
  top: -110px;
  margin-bottom: -110px;

  .heading {
    position: relative;

    &__image {
      height: 450px;
      width: 100%;
      object-fit: cover;

      @media screen and (min-width: 960px) {
        height: 500px;
      }
    }

    &__text {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      h1 {
        font-weight: bold;
        font-size: 40px;

        @media screen and (min-width: 960px) {
          font-size: 60px;
        }
      }

      p {
        padding: 0 16px;
      }
    }

    &__button {
      background-color: #1b8ecf;
      color: white;

      i {
        color: white;
      }

      &:hover {
        background-color: white;
        color: #1b8ecf;

        i {
          color: #1b8ecf;
        }
      }
    }
  }

  .contact {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;

    &__container {
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;

      .contact {
        &__card {
          position: relative;
          top: 30px;
          padding: 30px 20px;
          max-height: fit-content;
          background-color: rgba(255, 255, 255, 0.7);

          h2,
          p {
            color: #1b8ecf;
          }
        }

        &__button {
          background-color: #1b8ecf;
          color: white;
        }
      }
    }
  }
`;
