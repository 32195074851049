/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

const CardWide = forwardRef((props, ref) => {
  const { className, headerElem, children, headerTitle, ...rest } = props;
  const HeaderElement = headerElem;
  return (
    <>
      <Grid {...rest}>
        <Grid item xs={12}>
          <div className={className}>
            <HeaderElement ref={ref}>{headerTitle}</HeaderElement>
            {children}
          </div>
        </Grid>
      </Grid>
    </>
  );
});

CardWide.propTypes = {
  className: PropTypes.string,
  headerElem: PropTypes.string,
  children: PropTypes.node,
  headerTitle: PropTypes.string
};

export default CardWide;
