import React from 'react';
import Img from 'gatsby-image/withIEPolyfill';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Grid, Container, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import { StyledRibbon } from './StyledRibbon';

const Ribbon = ({ heading, pitchLine, fluid = null, fixed = null }) => (
  <StyledRibbon>
    <Img
      loading='auto'
      className='heading__image'
      fluid={fluid}
      fixed={fixed}
      objectFit='cover'
      alt='E S I - Installatietechniek B.V.'
    />
    <div className='contact' data-sal='slide-down'>
      <div className='contact__container'>
        <Container fixed>
          <Grid>
            <Grid item xs={12} md={6}>
              <Paper className='contact__card'>
                <h1 className='text--brand'>{heading}</h1>
                <p className='mb-2x'>{pitchLine}</p>

                <div className='hidden-sm hidden-md hidden-lg'>
                  <a href='tel:0642090304'>
                    <Button
                      variant='contained'
                      className='btn heading__button mt-4x '
                    >
                      <i className='material-icons mr-1x'>phone</i>Neem contact
                      op
                    </Button>
                  </a>
                </div>
                <AniLink fade to='/contact' className='hidden-xs'>
                  <Button
                    role='button'
                    variant='contained'
                    className='btn heading__button mt-4x '
                  >
                    <i className='material-icons mr-1x'>phone</i>Neem contact op
                  </Button>
                </AniLink>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  </StyledRibbon>
);

Ribbon.propTypes = {
  heading: PropTypes.string.isRequired,
  pitchLine: PropTypes.string.isRequired,
  fluid: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fixed: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
};

export default Ribbon;
