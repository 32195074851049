/* eslint-disable max-len */
/* eslint-disable quotes */
import React from 'react';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';

import SinglePageLayout from '../../components/SinglePageLayout';

export const query = graphql`
  query {
    mobileImage: file(relativePath: { eq: "duurzaam/duurzaam-11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    desktopImage: file(relativePath: { eq: "duurzaam/duurzaam-11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1300, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    CardHorizontal1Image: file(
      relativePath: { eq: "duurzaam/duurzaam-9.jpg" }
    ) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;

const textTop = {
  header: `E S I - Warmtepomp installatie voor duurzame warmte.`,
  textWithLink: () => (
    <>
      <p>
        De ontwikkelingen op het gebied van duurzame oplossingen volgen elkaar
        in een rap tempo op. Voor wie flink wil besparen op de energierekening
        of zelfs zelfvoorzienend wil zijn, is een warmtepomp zeer interessant.
      </p>
    </>
  ),
};

const textBottom = {
  header: `Subsidie`,
  textWithLink: () => (
    <>
      <p>
        De overheid heeft een subsidie voor warmtepompen beschikbaar gesteld om
        zo de investering in duurzame oplossingen te stimuleren. Het is nu dus
        hét moment om over te gaan!
      </p>
      <div className='hidden-sm hidden-md hidden-lg'>
        <a href='tel:0642090304'>
          <Button
            role='button'
            variant='contained'
            className='btn btn--secondary heading__button mt-4x '
          >
            <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
          </Button>
        </a>
      </div>
      <AniLink fade to='/contact' className='hidden-xs'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x '
        >
          <i className='material-icons mr-1x'>phone</i>Krijg advies op maat
        </Button>
      </AniLink>
      <AniLink fade to='/duurzaam' className='ml-sm-4x'>
        <Button
          role='button'
          variant='contained'
          className='btn btn--secondary heading__button mt-4x'
        >
          <i className='material-icons mr-1x'>link</i>Terug naar overzicht
        </Button>
      </AniLink>
    </>
  ),
};

const Warmtepompen = ({ data, path, location }) => {
  const seo = {
    title: `Warmtepompen`,
    description: `Deze duurzame oplossing van E S I - Installatietechniek B.V. haalt met een warmtewisselaar warmte uit de lucht, bodem of grondwater`,
    pathname: path,
    location,
  };

  const CardHorizontal1 = {
    noSpace: false,
    img: data.CardHorizontal1Image.childImageSharp.fluid,
    imgAlt: 'E S I - Installatietechniek B.V.',
    imgTitle: 'E S I - Installatietechniek B.V.',
    header: 'Hoe werken warmtepompen?',
    inner:
      'Deze duurzame oplossing haalt met een warmtewisselaar warmte uit de lucht, bodem of grondwater. De warmte gaat vervolgens door een proces met een koudemiddel en wordt door de warmtepomp naar een hogere temperatuur gebracht. Via lage temperatuur verwarming of vloerverwarming geniet u van een heerlijke temperatuur in huis. Eventueel zorgt de warmtepomp ook voor warm tapwater en passieve koeling. Doordat dit systeem elektriciteit gebruikt in plaats van gas, wordt de gasrekening en de CO2-uitstoot fors verlaagd',
  };

  const sources = [
    data.mobileImage.childImageSharp.fluid,
    {
      ...data.desktopImage.childImageSharp.fluid,
      media: '(min-width: 970px)',
    },
  ];

  const ribbon = {
    image: sources,
    heading: `E S I - warmtepompen`,
    pitchLine: `Wordt duurzaam met de warmtepompen van E S I - Installatietechniek B.V.`,
  };

  return (
    <>
      <SinglePageLayout
        seo={seo}
        ribbon={ribbon}
        textTop={textTop}
        CardHorizontal1={CardHorizontal1}
        textBottom={textBottom}
      />
    </>
  );
};

Warmtepompen.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
  path: PropTypes.string,
};

export default Warmtepompen;
